










import {defineComponent} from '@vue/composition-api';
import StembleCanvas from '@/common/components/StembleCanvas.vue';

export default defineComponent({
  name: 'DrawingComponent',
  components: {StembleCanvas},
});
